<script>
    import Layout from '../../layouts/main'
    import PageHeader from '@/components/page-header'
    
    export default {
        components: { Layout, PageHeader },
        data() {
            return {
                title : 'Dashboard',
                items: [
                    {
                        text: "Dashboard",
                        href: "/ss/admin/dashboard",
                        active: true,
                    },
                ],
            }
        },
        mounted(){
        }
    }
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-xl-8">
                <div class="card">
                    <div>
                        <div class="row">
                            <div class="col-lg-9 col-sm-8">
                                <div class="p-4">
                                    <h5 class="text-primary">Simulation Payment</h5>
                                    <p>New Feature</p>

                                    <div class="text-muted">
                                        <p>Payment simulation can make it easier for users to make payments as if they were paid in real-time</p>
                                    </div>
                                    <div class="button-items">
                                        <router-link :to="{name : 'payment.simulation'}">
                                            <b-button variant="primary">
                                                <i class="bx bx-right-arrow-alt font-size-20 align-middle me-1"></i>
                                                Explore
                                            </b-button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-4 align-self-center">
                                <div>
                                <img
                                    src="@/assets/images/simulation_payment.svg"
                                    alt
                                    class="img-fluid d-block"
                                />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
